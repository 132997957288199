import * as Yup from 'yup';

import { TFormFieldsFactory } from '../models/form-field.model';

export const passwordResetForm: TFormFieldsFactory<'PasswordResetForm'> = ({ t }) => [
    {
        name: 'newPassword',
        type: 'password',
        placeholder: t.newPassword.placeholder,
        isRequired: true,
        initialValue: '',
        schema: Yup.string()
            .min(8, t.newPassword.error.min)
            .max(32, t.newPassword.error.max)
            .required(t.newPassword.error.required),
    },
    {
        name: 'repeatPassword',
        type: 'password',
        placeholder: t.repeatPassword.placeholder,
        isRequired: true,
        initialValue: '',
        schema: Yup.string()
            .min(8, t.repeatPassword.error.min)
            .max(32, t.repeatPassword.error.max)
            .oneOf([Yup.ref('newPassword'), null], t.repeatPassword.error.mustMatch)
            .required(t.repeatPassword.error.required),
    },
    {
        name: 'submit',
        type: 'submit',
        label: t.submit.label,
    },
    {
        name: 'token',
        label: 'token',
        type: 'hidden',
        initialValue: '',
        schema: Yup.string().required(t.token.error),
    },
];
