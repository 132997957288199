import React, { useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
    grid,
    gridSuccess,
    title as titleClass,
    formWrapper,
    thankYouBox,
    sectionBox,
} from './password-reset.module.scss';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { ISection } from '../../models/section.model';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { passwordResetForm } from '../../formik/password-reset.form';
import { useUser } from '../../hooks/use-user';
import useTranslations from '../../hooks/use-translations';
import { getUrlParamValue } from '../../utils/get-url-param-value';
import { usePagePathname } from '../../hooks/use-page-pathname';

import Section from '../hoc/section';
import FormGenerator from '../molecules/form-generator';
import ThankYouBox from '../molecules/thank-you-box';

export interface IPasswordResetProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

interface IPasswordResetQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
}

const PasswordReset: React.FC<IPasswordResetProps> = ({ className = '', TitleTag, section }) => {
    const t = useTranslations('PasswordReset');
    const [title, successTitle, successDescription] = section.content.texts;
    const userState = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(userState.resetPassword.errors);
    const sectionRef = useRef<HTMLElement>(null);

    const { allPage } = useStaticQuery<IPasswordResetQueryResult>(query);
    const clientLoginPathname = usePagePathname(allPage, 'login-client');
    const candidateLoginPathname = usePagePathname(allPage, 'login-candidate');

    const token = getUrlParamValue('token');

    useEffect(() => {
        formikRef.current?.setFieldValue('token', token || '');
    }, [formikRef, token]);

    useEffect(() => {
        if (!sectionRef.current) return;
        if (userState.resetPassword.isSuccess) {
            sectionRef.current.scrollIntoView();
        }
    }, [userState.resetPassword.isSuccess]);

    return (
        <Section
            className={`${className} ${sectionBox}`}
            classes={{
                container: `${grid} ${userState.resetPassword.isSuccess ? gridSuccess : ''}`,
                title: titleClass,
            }}
            TitleTag={TitleTag}
            style={section.style}
            title={title}
            ref={sectionRef}
            sectionId={section.sectionId}
            css={section.css}
            roundedSection={true}
        >
            {!userState.resetPassword.isSuccess ? (
                <FormGenerator
                    formRef={formikRef}
                    className={formWrapper}
                    formikProps={{
                        onSubmit: (values) => userState.resetPassword.fetch(values),
                    }}
                    name="PasswordResetForm"
                    fields={passwordResetForm}
                    errorMessage={globalErrorMessage}
                    submitLoading={userState.resetPassword.isLoading}
                />
            ) : (
                <ThankYouBox
                    className={thankYouBox}
                    data={{
                        title: successTitle || '',
                        description: successDescription || '',
                        buttons: [
                            {
                                url: clientLoginPathname,
                                text: t.loginClient,
                            },
                            {
                                url: candidateLoginPathname,
                                text: t.loginCandidate,
                            },
                        ],
                    }}
                />
            )}
        </Section>
    );
};

const query = graphql`
    query {
        allPage(filter: { type: { in: ["login-client", "login-candidate"] } }) {
            edges {
                node {
                    locale
                    type
                    pathname
                }
            }
        }
    }
`;

export default PasswordReset;
